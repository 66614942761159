"use client"
import React from "react"

import Edges from "../edges/Edges"
import { useLocation } from "@reach/router"
import { Link } from "gatsby"
import { motion, AnimatePresence } from "framer-motion"

const NavSlideOut = ({ isOpen, setIsOpen, menu }) => {
  const location = useLocation()

  const sideVariants = {
    closed: {
      x: "100%",
      transition: {
        staggerChildren: 0.2,
        staggerDirection: -1,
        type: `tween`,
        stiffness: 50,
        duration: 0.3,
      },
    },
    open: {
      x: 0,
      transition: {
        staggerChildren: 0.2,
        staggerDirection: 1,
        type: `tween`,
        stiffness: 50,
        duration: 0.3,
      },
    },
  }
  const itemVariants = {
    closed: {
      opacity: 0,
    },
    open: {
      opacity: 1,
    },
  }

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.aside
          className="block md:hidden"
          initial={{ width: 0 }}
          animate={{ width: "100%" }}
          exit={{
            width: 0,
            transition: { delay: 0.7, duration: 0.3 },
          }}
        >
          <motion.div
            initial="closed"
            animate="open"
            exit="closed"
            variants={sideVariants}
            className="fixed top-0 z-[9999] h-full w-full bg-woodsmoke flex flex-col"
          >
            <div className="flex flex-col justify-center h-full">
              <Edges
                size="sm"
                className="relative  flex h-full w-full  font-normal text-white md:items-end"
              >
                <motion.div className=" pr-[33px] " variants={itemVariants}>
                  <div className="relative mb-[82px] w-fit flex flex-col  mx-auto ">
                    {menu?.map((item, index) => (
                      <div key={index} className=" flex flex-col text-center">
                        <Link
                          onClick={() => setIsOpen(!isOpen)}
                          to={item.url}
                          className={
                            !location?.pathname?.includes(item.url)
                              ? "text-white font-bold font-inter mb-[35px] text-[22px]  leading-[30px] hover:underline"
                              : "text-white font-bold font-inter mb-[35px] text-[22px]  leading-[30px] underline hover:underline"
                          }
                        >
                          {item.label}
                        </Link>
                      </div>
                    ))}
                  </div>
                </motion.div>
              </Edges>
            </div>
          </motion.div>
        </motion.aside>
      )}
    </AnimatePresence>
  )
}

export default NavSlideOut
