import * as React from "react"
import Header from "../header/Header"
import Footer from "../footer/Footer"

const Layout = ({ children }) => {
  const { props } = children

  const backgroundColor = props?.data?.post?.post_content?.color
  return (
    <>
      {props?.data?.post && (
        <div
          style={{ backgroundColor: backgroundColor }}
          className="absolute top-0 left-0 right-0 h-[250px] z-1"
        ></div>
      )}
      <Header />

      <main>{children}</main>
      <Footer />
    </>
  )
}

export default Layout
