"use client"
import React, { useState, useEffect } from "react"
import LogoWhite from "../../assets/LogoWhite.svg"
import { Link } from "gatsby"
import { useMenuItems } from "../../hooks/useMenuItems"
import Hamburg from "../../assets/Hamburg.svg"
import NavSlideOut from "./NavSlideOut"
import Close from "../../assets/close.svg"
import { useLocation } from "@reach/router"

const Header = () => {
  const location = useLocation()
  const [isOpen, setIsOpen] = useState(false)
  useEffect(() => {
    window?.document && isOpen
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "visible")
  }, [isOpen])

  const menuItems = useMenuItems({ location: "HEADERMENU" })

  return (
    <>
      <div className="h-[107px] flex items-center justify-between md:justify-center text-white mx-auto max-w-7xl mb-[121px] relative top-[51px] z-[99999] px-[15px]">
        <a href="/" aria-label="Home-Logo">
          <LogoWhite className="h-[100px] min-w-[87px]" />
        </a>
        <div className="ml-auto relative flex flex-row flex-end mb-auto hidden md:block">
          {menuItems?.map((item, index) => {
            return (
              <Link
                to={item.url}
                key={index}
                className={
                  !location?.pathname?.includes(item.url)
                    ? "text-p mx-[20px] font-normal font-inter  px-[20px] py-[2px]"
                    : "text-p mx-[20px] font-normal font-inter px-[20px] py-[2px] border-[1px] rounded-[5px]"
                }
              >
                {item.label}
              </Link>
            )
          })}
        </div>
        <button
          role="button"
          aria-label="Open Menu"
          onClick={() => {
            setIsOpen(!isOpen)
          }}
        >
          {isOpen ? (
            <Close
              className="block md:hidden absolute cursor-pointer top-0 right-8 h-fit "
              onClick={() => {
                setIsOpen(!isOpen)
              }}
            />
          ) : (
            <Hamburg className="h-fit absolute block md:hidden w-[30px]  top-0 right-4 fill-white cursor-pointer" />
          )}
        </button>
      </div>
      {isOpen && (
        <NavSlideOut isOpen={isOpen} setIsOpen={setIsOpen} menu={menuItems} />
      )}
    </>
  )
}

export default Header
