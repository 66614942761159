import React from "react"

const Footer = () => {
  return (
    <div className="max-w-7xl relative mx-auto pr-[15px] md:pr-0 mt-[45px]">
      <div className="text-white flex justify-end pb-[49px]">
        © 2023 - All For Web For All
      </div>
    </div>
  )
}

export default Footer
